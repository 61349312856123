import ProService from "@/services/pro-service";
import ProductService from "@/services/product-service";
import UploadService from "@/services/upload-service";
import UserService from "@/services/user-service";
import { ProAvailability, ProBusyTime } from "@/types/pro-availability";
import { Product } from "@/types/product";
import { FileData } from "@/types/upload";
import {
  ArtistStatistics,
  ProfileCompleteness,
  ProPriceCategory,
  User,
  UserStatus,
  UserType,
} from "@/types/user";
import { handleError } from "@/utils/error";
import dayjs from "dayjs";
import { defineStore } from "pinia";
import StripeService from "@/services/stripe-service";

const userService = new UserService("users");
const proService = new ProService("pros");
const productService = new ProductService("products");
const stripeService = new StripeService("stripe");
const uploadService = new UploadService("uploads");

export const useUserStore = defineStore("user", {
  state: () => ({
    userData: {} as User,
    userProfileCompleteness: {} as ProfileCompleteness,

    availability: {} as Partial<ProAvailability>,
    products: [] as Product[],

    user: {} as User,
    userStatistics: {} as ArtistStatistics,
  }),
  actions: {
    async getByUniqueName(uniqueName: string): Promise<User> {
      try {
        const user = await userService.getByUniqueName(uniqueName);
        this.user = user;
        return user;
      } catch (error) {
        throw handleError(error);
      }
    },

    async sendMessage(userId: string, message: string) {
      try {
        return await userService.sendMessage(userId, message);
      } catch (error) {
        throw handleError(error);
      }
    },

    async getArtistStatistics() {
      try {
        this.userStatistics = await userService.getArtistStatistics(
          this.user._id,
        );
      } catch (error) {
        throw handleError(error);
      }
    },

    async getMe(): Promise<{ user: User }> {
      try {
        let userData = await userService.getMe();
        if (!userData.timezone) {
          userData = await this.updateTimezone();
        }
        this.setData(userData);

        return { user: userData };
      } catch (error) {
        throw handleError(error);
      }
    },

    async canChat(proId: string): Promise<boolean> {
      try {
        return (await userService.canChat(proId)).canChat;
      } catch (error) {
        throw handleError(error);
      }
    },

    async updatePurchase(
      instagram: string,
      whatDescribeYouBest: string,
    ): Promise<void> {
      try {
        const user = await userService.updatePurchase(
          instagram,
          whatDescribeYouBest,
        );
        this.setData(user);
      } catch (error) {
        throw handleError(error);
      }
    },
    async updateMe({
      emailConfirmation,
      ...data
    }: Partial<User & { emailConfirmation: any }>): Promise<User> {
      try {
        const user = await userService.updateMe(data);
        this.setData(user);

        return user;
      } catch (error) {
        throw handleError(error);
      }
    },
    async updatePhone(phone: string): Promise<User> {
      try {
        const user = await userService.updateMe({ phone });
        this.setData(user);
        return user;
      } catch (error) {
        throw handleError(error);
      }
    },
    async createStripeCustomer(): Promise<User> {
      try {
        const user = await stripeService.createStripeCustomer();
        this.setData(user);
        return user;
      } catch (e) {
        throw handleError(e);
      }
    },
    async addStripeCard(holderName: string, cardToken: string) {
      try {
        return stripeService.addCard(holderName, cardToken);
      } catch (e) {
        throw handleError(e);
      }
    },
    async getSavedCards() {
      try {
        return stripeService.getSavedCards();
      } catch (e) {
        throw handleError(e);
      }
    },
    async goLive(): Promise<void> {
      try {
        const res = await userService.goLive();
        this.setData(res);
      } catch (error) {
        throw handleError(error);
      }
    },
    async updateTimezone(): Promise<User> {
      return userService.updateTimezone(dayjs.tz.guess());
    },

    async changeProfilePicture(data: FileData): Promise<{ user: User } | void> {
      try {
        await uploadService.uploadFile(data);
        return await this.getMe();
      } catch (error) {
        throw handleError(error);
      }
    },

    async changeProfileVideo(data: FileData): Promise<{ user: User } | void> {
      try {
        await uploadService.uploadFile(data);
        return await this.getMe();
      } catch (error) {
        throw handleError(error);
      }
    },

    async removeProfilePicture(): Promise<User | void> {
      try {
        const res = await userService.removeProfilePicture();
        this.setData(res);
      } catch (error) {
        console.error(error);
        throw handleError(error);
      }
    },

    setData(data: User): void {
      localStorage.setItem("user", JSON.stringify(data));
      this.userData = data;
    },

    async getProAvailability(proId: string): Promise<ProAvailability | void> {
      try {
        const res = await proService.getProAvailability(proId);
        this.availability = res;

        return res;
      } catch (error) {
        throw handleError(error);
      }
    },

    async getCurrentProBusyTime(
      startDate: Date,
      endDate: Date,
    ): Promise<ProBusyTime> {
      try {
        const res = await userService.getCurrentProBusyTime(startDate, endDate);
        return res;
      } catch (e) {
        throw handleError(e);
      }
    },

    async getProProducts(proId: string): Promise<Product[] | void> {
      try {
        const res = await productService.list(proId);
        if (res) {
          this.products = res;
        }
        return res;
      } catch (error) {
        throw handleError(error);
      }
    },

    async getProData(): Promise<{ user: User } | void> {
      try {
        await Promise.all([
          this.getProAvailability(this.userData._id!),
          this.getProProducts(this.userData._id!),
        ]);
      } catch (error) {
        throw handleError(error);
      }
    },

    async updateProducts(products: Product[]): Promise<Product[] | void> {
      try {
        const promises = products.map(async (product) =>
          productService.upsert(product),
        );
        await Promise.all(promises);
        await userService.updateMyPrices();
      } catch (error) {
        throw handleError(error);
      }
    },

    async updateAvailability(
      availability: ProAvailability,
    ): Promise<ProAvailability | void> {
      try {
        const res = await proService.upsertProAvailability(
          this.userData._id!,
          availability,
          this.userData.timezone || "",
        );
        this.availability = res;

        return res;
      } catch (error) {
        throw handleError(error);
      }
    },

    logout(): void {
      this.userData = {
        _id: "",
        email: "",
        firstName: "",
        lastName: "",
        uniqueName: "",
        timezone: dayjs.tz.guess(),
        status: UserStatus.pending,
        type: UserType.customer,
        priceStartingFrom: 0,
        pricing: ProPriceCategory.free,
        profileCompleteness: {
          complete: false,
          basicInformation: false,
          addTracks: false,
          requestPaidProduct: false,
        },
        ratings: [],
      };
      this.availability = {};
      this.products = [];
    },
  },
});
