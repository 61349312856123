import {
  Authentication,
  ProCandidate,
  ProCandidateRegister,
} from "@/types/authentication";
import { handleError } from "@/utils/error";
import BaseService from "./base-service";
import {
  getGAVariables,
  saveGtagVarsInCookies,
  splitFullName,
} from "@/utils/helpers";
import { User } from "@/types/user";

class AuthenticationService extends BaseService {
  constructor(_rootEndpoint: string) {
    super(_rootEndpoint);
  }

  public async register(
    fullName: string,
    email: string,
    password: string,
    timezone: string,
    code?: string,
  ): Promise<Authentication | void> {
    try {
      const analytics = await getGAVariables();
      const [firstName, lastName] = splitFullName(fullName);
      return await this.post(`${this.getServiceContext}/register`, {
        firstName,
        lastName,
        email,
        password,
        timezone,
        code,
        analytics,
      });
    } catch (error) {
      throw handleError(error);
    }
  }

  public async applyPro(
    props: ProCandidateRegister,
  ): Promise<ProCandidate | void> {
    try {
      return await this.post(`${this.getServiceContext}/apply`, props);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async login(
    email: string,
    password: string,
  ): Promise<Authentication | void> {
    try {
      return await this.post(`${this.getServiceContext}/login`, {
        email,
        password,
      });
    } catch (error) {
      throw handleError(error);
    }
  }

  public async forgotPassword(email: string): Promise<Authentication | void> {
    try {
      return await this.post(`${this.getServiceContext}/forgot-password`, {
        email,
      });
    } catch (error) {
      throw handleError(error);
    }
  }

  public async resetPassword(
    newPassword: string,
    token: string,
  ): Promise<Authentication | void> {
    try {
      return await this.post(`${this.getServiceContext}/reset-password`, {
        newPassword,
        token,
      });
    } catch (error) {
      throw handleError(error);
    }
  }

  public async logout(): Promise<void> {
    try {
      return await this.post(`${this.getServiceContext}/logout`, {});
    } catch (error) {
      throw handleError(error);
    }
  }

  public async googleLogin(
    googleDto: {
      id_token: string;
      access_token: string;
      refresh_token?: string;
      code?: string;
    },
    code?: string,
  ): Promise<Authentication | void> {
    try {
      return await this.post(`${this.getServiceContext}/google`, googleDto);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async getGoogleLoginUrl(
    proCandidateCode?: string,
  ): Promise<{ url: string; verifier: string }> {
    try {
      await saveGtagVarsInCookies();
      const query = proCandidateCode
        ? `?proCandidateCode=${proCandidateCode}`
        : "";
      return await this.get(`${this.getServiceContext}/google${query}`);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async getGoogleLinkUrl(
    isOnboarding: boolean,
  ): Promise<{ url: string }> {
    try {
      return await this.get(
        `${
          this.getServiceContext
        }/google/link?settings=${(!isOnboarding).toString()}`,
      );
    } catch (error) {
      throw handleError(error);
    }
  }

  public async getTwitterLoginUrl(): Promise<{
    url: string;
    verifier: string;
  }> {
    try {
      return await this.get(`${this.getServiceContext}/twitter`);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async twitterCallback(
    state: string,
    code: string,
    code_verifier: string,
  ): Promise<Authentication | void> {
    try {
      return await this.get(
        `${this.getServiceContext}/twitter/callback?state=${state}&code=${code}&code_verifier=${code_verifier}`,
      );
    } catch (error) {
      throw handleError(error);
    }
  }

  public async googleCallback(
    code: string,
    candidateCode: string,
  ): Promise<{ auth_token: string }> {
    try {
      const analytics = await getGAVariables();
      return await this.post(`${this.getServiceContext}/google/callback`, {
        code,
        candidateCode,
        analytics,
      });
    } catch (error) {
      throw handleError(error);
    }
  }

  public async googleLinkCallback(code: string, state: string): Promise<User> {
    try {
      return await this.post(`${this.getServiceContext}/google/link/callback`, {
        code,
        state,
      });
    } catch (error) {
      throw handleError(error);
    }
  }

  public async twitterLogin(
    accessToken: string,
    code?: string,
  ): Promise<Authentication | void> {
    try {
      return await this.post(`${this.getServiceContext}/twitter`, {
        code,
        access_token: accessToken,
      });
    } catch (error) {
      throw handleError(error);
    }
  }
}

export default AuthenticationService;
