import { ProAvailability, TimestampSlot } from "@/types/pro-availability";
import { RelatedPros, User } from "@/types/user";
import { handleError } from "@/utils/error";
import BaseService from "./base-service";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { UserType } from "@/types/user";
dayjs.extend(utc);
dayjs.extend(timezone);

class ProService extends BaseService {
  constructor(_rootEndpoint: string) {
    super(_rootEndpoint);
  }

  public async list(
    uri: string,
  ): Promise<{ total: number; data: User[] } | void> {
    try {
      return await this.get(`${this.getServiceContext}?${uri}`);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async getProUniqueNames(): Promise<string[] | void> {
    try {
      const { data } = await useFetch<string[]>(
        `${this.getServiceContext}/unique-names`,
      );
      return data.value || [];
    } catch (error) {
      throw handleError(error);
    }
  }

  public async getFeaturedPros(): Promise<{
    featured: User[];
    ars: User[];
    managers: User[];
  } | void> {
    try {
      const { data } = await useFetch<{
        featured: User[];
        ars: User[];
        managers: User[];
      }>(`${this.getServiceContext}/featured-pros`);
      return data.value || { featured: [], ars: [], managers: [] };
    } catch (error) {
      throw handleError(error);
    }
  }

  public async getRelatedPros(uniqueName: string): Promise<RelatedPros> {
    try {
      return await this.get(`${this.getServiceContext}/related/${uniqueName}`);
    } catch (error) {
      throw handleError(error);
    }
  }

  // public async getByUniqueName(uniqueName: string): Promise<User | void> {
  //   try {
  //     return await this.get(`${this.getServiceContext}/unique/${uniqueName}`);
  //   } catch (error) {
  //     throw handleError(error);
  //   }
  // }

  public async getByUniqueName(uniqueName: string): Promise<User | undefined> {
    const { data } = await useFetch<User>(
      `${this.getServiceContext}/unique/${uniqueName}`,
      {
        key: `pros/${uniqueName}`,
      },
    );
    if (data.value) {
      if (data.value.type !== UserType.pro) {
        throw { statusCode: 404, message: "Pro not found" };
      }
      return data.value;
    } else {
      throw { statusCode: 404, message: "Pro not found" };
    }
  }

  public async getById(id: string): Promise<User | void> {
    try {
      return await this.get(`${this.getServiceContext}/${id}`);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async getProById(id: string): Promise<User | void> {
    try {
      return await this.get(`${this.getServiceContext}/${id}`);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async getProAvailability(proId: string): Promise<ProAvailability> {
    return await this.get(`${this.getServiceContext}/${proId}/availability`);
  }

  public async getProCalendarAvailability(proId: string): Promise<{
    availableDates: {
      start: Date;
      end: Date;
    };
    intervals: TimestampSlot[];
  }> {
    return await this.get(
      `${this.getServiceContext}/${proId}/calendar-availability`,
    );
  }

  public async upsertProAvailability(
    proId: string,
    availability: ProAvailability,
    timezone: string,
  ): Promise<ProAvailability> {
    const data = {
      proId: availability.proId,
      monday: availability.monday,
      tuesday: availability.tuesday,
      wednesday: availability.wednesday,
      thursday: availability.thursday,
      friday: availability.friday,
      saturday: availability.saturday,
      sunday: availability.sunday,
      timezone,
    };

    return await this.post(
      `${this.getServiceContext}/${proId}/availability`,
      data,
    );
  }
}

export default ProService;
