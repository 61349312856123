import { FeedbackProduct, ProductRequest } from "@/store/products";
import { Product } from "@/types/product";
import { handleError } from "@/utils/error";
import { Stripe } from "stripe";
import BaseService from "./base-service";
import { AnalyticsVars } from "@/types/analytics";

class ProductService extends BaseService {
  constructor(_rootEndpoint: string) {
    super(_rootEndpoint);
  }

  public async list(proId: string): Promise<Product[] | void> {
    try {
      return await this.get(`${this.getServiceContext}/pro/${proId}`);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async upsertProduct(
    product: FeedbackProduct,
  ): Promise<Product[] | void> {
    try {
      return await this.post(`${this.getServiceContext}`, product);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async getById(id: string): Promise<Product | void> {
    try {
      return await this.get(`${this.getServiceContext}/${id}`);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async getPaymentDetails(
    orderId: string,
  ): Promise<{ price: number; total: number; serviceFee: number }> {
    try {
      return await this.get(
        `${this.getServiceContext}/${orderId}/payment/details`,
      );
    } catch (error) {
      throw handleError(error);
    }
  }

  public async authorizePayment(
    productId: string,
    token: string,
    body: ProductRequest & {
      songUrl: string;
      analytics: AnalyticsVars;
    },
    checkoutId: string,
  ): Promise<Stripe.PaymentIntent | never> {
    try {
      return (await this.post(
        `${this.getServiceContext}/${productId}/authorize-payment?stripeToken=${token}`,
        { ...body, checkoutId },
      )) as Stripe.PaymentIntent;
    } catch (error) {
      throw handleError(error);
    }
  }

  public async createPaymentIntent(
    productId: string,
    body: ProductRequest & {
      songUrl: string;
      analytics: AnalyticsVars;
    },
  ): Promise<string> {
    try {
      return await this.post(
        `${this.getServiceContext}/${productId}/payment-intent`,
        body,
      );
    } catch (e) {
      throw handleError(e);
    }
  }

  public async submitFreeRequest(
    productId: string,
    body: ProductRequest,
  ): Promise<ProductRequest> {
    try {
      return await this.post(
        `${this.getServiceContext}/${productId}/submit-free`,
        body,
      );
    } catch (error) {
      throw handleError(error);
    }
  }

  public async getProductTaxes(
    productId: string,
    country: string,
    zipCode?: string,
  ) {
    return this.post(`${this.getServiceContext}/${productId}/taxes`, {
      country,
      zipCode,
    });
  }

  public async upsert(prod: Product): Promise<Product | void> {
    try {
      const data = {
        proId: prod.proId,
        type: prod.type,
        price: prod.price,
        isAvailable: prod.isAvailable,
      };
      return await this.post(`${this.getServiceContext}`, data);
    } catch (error) {
      throw handleError(error);
    }
  }
}

export default ProductService;
