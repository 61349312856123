import { Exception, createException } from "@/utils/error";
// import { useAuthenticationStore } from "@/store/authentication";
import config from "@/config";

export type method = "GET" | "POST" | "PUT" | "DELETE";
export type contentType =
  | "application/x-www-form-urlencoded"
  | "application/json"
  | "multipart/form-data";

abstract class BaseService {
  private requestInit: RequestInit;
  private serviceContext: string | undefined;
  private headers: Headers = new Headers();
  private rootEndpoint: string = "";

  constructor(
    _rootEndpoint?: string,
    _requestInit: RequestInit = Object.create({}),
    _serviceContext?: string,
  ) {
    this.requestInit = _requestInit;
    this.rootEndpoint = _rootEndpoint ?? "";
    // this.serviceContext = _serviceContext ?? process.env.VUE_APP_API_BASE_URL!;
    // if (_rootEndpoint) {
    //   this.serviceContext = `${this.serviceContext}/${_rootEndpoint}`;
    // }
  }

  public setServiceContext(context: string): void {
    this.serviceContext = context;
  }

  public get getServiceContext(): string {
    if (!this.serviceContext) {
      this.serviceContext = this.serviceContext ?? config("apiBaseUrl");
      if (this.rootEndpoint) {
        this.serviceContext = `${this.serviceContext}/${this.rootEndpoint}`;
      }
    }
    return this.serviceContext;
  }

  public get getHeaders(): Headers {
    this.headers.set("Content-Type", "application/json");
    return this.headers;
  }

  public setAuthorizationHeader(authToken: string): void {
    this.headers.set("Authorization", `Bearer ${authToken}`);
  }

  public setContentTypeHeader(contentType: contentType): void {
    this.headers.set("Content-Type", contentType);
  }

  protected buildRequest(url: string, method: method, body?: string): Request {
    this.requestInit.headers = this.getHeaders;
    this.requestInit.method = method;
    this.requestInit.body = body;
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      this.setAuthorizationHeader(authToken);
    }

    return new Request(url, this.requestInit);
  }

  protected async get<ReturnType>(url: string): Promise<ReturnType> {
    const request = () => fetch(this.buildRequest(url, "GET"));
    return (await this.checkBeforeCrudOperation<ReturnType>(
      request,
    )) as ReturnType;
  }

  protected async post<PayloadType, ReturnType>(
    url: string,
    payload: PayloadType,
  ): Promise<ReturnType> {
    const request = () =>
      fetch(this.buildRequest(url, "POST", JSON.stringify(payload)));
    return (await this.checkBeforeCrudOperation<ReturnType>(
      request,
    )) as ReturnType;
  }

  protected async update<PayloadType, ReturnType>(
    url: string,
    payload: PayloadType,
  ): Promise<ReturnType> {
    const request = () =>
      fetch(this.buildRequest(url, "PUT", JSON.stringify(payload)));
    return (await this.checkBeforeCrudOperation<ReturnType>(
      request,
    )) as ReturnType;
  }

  protected async delete<PayloadType, ReturnType>(
    url: string,
    payload: PayloadType,
  ): Promise<ReturnType> {
    const request = () =>
      fetch(this.buildRequest(url, "DELETE", JSON.stringify(payload)));
    return (await this.checkBeforeCrudOperation<ReturnType>(
      request,
    )) as ReturnType;
  }

  protected async checkResponse<ReturnType>(
    response: Response,
  ): Promise<ReturnType | never> {
    const isResponseOk = response.ok;
    try {
      if (!isResponseOk) {
        throw await response.json();
      }
      return await response.json();
    } catch (error) {
      console.error(error);
      if (!isResponseOk) {
        throw createException(error as Record<string, string>);
      }
      throw error;
    }
  }

  private checkForConnection() {
    if (!process.server) return window.navigator.onLine;
  }

  private async checkBeforeCrudOperation<ReturnType>(
    request: () => Promise<Response>,
  ): Promise<ReturnType | never> {
    try {
      if (this.checkForConnection()) {
        const response = await request();
        return await this.checkResponse<ReturnType>(response);
      }
      const error = {
        code: "3000",
        message: "Your internet connection is unstable.",
      };
      throw createException(error as Record<string, string>);
    } catch (error) {
      const e = error as Exception;
      if (e.code === "Unauthorized") {
        try {
          const response = await request();
          const res = await this.checkResponse<ReturnType>(response);
          return res;
        } catch (error) {
          console.log(error);
          throw error;
        }
      } else {
        throw createException(error as Record<string, string>);
      }
    }
  }
}

export default BaseService;
