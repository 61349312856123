export class Exception extends Error {
  constructor(public code: string, public message: string) {
    super(message);
  }
}

export const unknownError = new Exception(
  "UnknownError",
  "Something went wrong, please try again later or contact customer support.",
);

export function createException(error: Record<string, string>): Exception {
  if (error?.code && error?.message) {
    return new Exception(error.code, error.message);
  }
  return unknownError;
}

export function handleError(error: unknown): Exception {
  return error instanceof Exception ? error : unknownError;
}
