import config from "@/config";
import { AnalyticsVars } from "@/types/analytics";

export function runWithDelay(
  functionToExecute: TimerHandler,
  time = 5000,
): void {
  setTimeout(functionToExecute, time);
}

export function changeNamesToUpperCase(
  names: Array<{ id: number; name: string }>,
): Array<{ id: number; name: string }> {
  return names.map((item) => {
    return {
      id: item.id,
      name:
        item.name.charAt(0).toUpperCase() +
        item.name.slice(1).replaceAll("_", " "),
    };
  });
}

export function capitalize(value: string): string {
  return value.replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase());
}

export function changeNamesToSnakeCase(names: string[]): string[] {
  return names.map((name) => {
    return name.charAt(0).toLowerCase() + name.slice(1).replaceAll(" ", "_");
  });
}

export function scrollToFirstError(errors: { [key: string]: string }): void {
  const firstErrorKey = Object.entries(errors).find(
    ([, value]) => value !== "",
  )?.[0];
  if (firstErrorKey) {
    scrollToId(firstErrorKey.replace("Error", ""));
  }
}

export function scrollToId(id: string): void {
  if (!id) return;
  const element = document.getElementById(id);
  if (!element) throw new ReferenceError(`No element found with id: ${id}`);
  element.scrollIntoView({ behavior: "smooth", block: "center" });
}
export function scrollToTargetAdjusted(id: string, offset?: number) {
  const element = document.getElementById(id);
  if (!element) throw new ReferenceError(`No element found with id: ${id}`);
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.pageYOffset - (offset || 0);

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
}

export function debounce<T>(fn: T, wait: number) {
  let timer: ReturnType<typeof setTimeout>;
  return (event: Event) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      if (typeof fn === "function") {
        fn(event);
      }
    }, wait);
  };
}

export function getCookieByName(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()!.split(";").shift();
  return undefined;
}

export function setCookie(name: string, value: string, expirationDays: number) {
  const date = new Date();
  date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

// TODO: move these to env variables
const prodGA = "JQ2Z192YPB";
const devGA = "GLSWHJG24V";

export async function getGAVariables(): Promise<AnalyticsVars> {
  try {
    const tag = config("isProduction") ? prodGA : devGA;
    const sessionIdPromise = new Promise<string>((resolve) => {
      window && // @ts-ignore
        window.gtag && // @ts-ignore
        window.gtag("get", `G-${tag}`, "session_id", resolve);
    });
    const sessionNumPromise = new Promise<string>((resolve) => {
      window && // @ts-ignore
        window.gtag && // @ts-ignore
        window.gtag("get", `G-${tag}`, "session_number", resolve);
    });

    // @ts-ignore
    const [ga_session_id, ga_session_number] = window.gtag
      ? await Promise.all([sessionIdPromise, sessionNumPromise])
      : [
          getCookieByName("ga_session_id"),
          getCookieByName("ga_session_number"),
        ];
    const ga_client_id = getCookieByName("_ga");
    const page_source = getCookieByName("signup_page_source");
    return {
      ga_session_id,
      ga_session_number: parseInt(ga_session_number || ""),
      ga_client_id: ga_client_id?.split(".").slice(2, 4).join("."),
      gclid: getCookieByName("gclid"),
      wbraid: getCookieByName("wbraid"),
      fbp: getCookieByName("_fbp"),
      fbc: getCookieByName("_fbc"),
      ttp: getCookieByName("_ttp"),
      ttclid: getCookieByName("ttclid"),
      utm_source: getCookieByName("utm_source"),
      utm_medium: getCookieByName("utm_medium"),
      ...(page_source && { page_source }),
    };
  } catch (e) {
    console.error("GA Variables error:", e);
  }
  return {};
}

export async function saveGtagVarsInCookies() {
  const tag = config("isProduction") ? prodGA : devGA;
  const sessionIdPromise = new Promise<string>((resolve) => {
    // @ts-ignore
    window.gtag("get", `G-${tag}`, "session_id", resolve);
  });
  const sessionNumPromise = new Promise<string>((resolve) => {
    // @ts-ignore
    window.gtag("get", `G-${tag}`, "session_number", resolve);
  });

  const [ga_session_id, ga_session_number] = await Promise.all([
    sessionIdPromise,
    sessionNumPromise,
  ]);
  setCookie("ga_session_id", ga_session_id, 1);
  setCookie("ga_session_number", ga_session_number, 1);
}

export function splitFullName(
  fullName: string,
): [firstName: string, lastName: string] {
  const [firstName, lastName] = fullName.split(/\s(.+)/, 2);
  return [firstName, lastName];
}

export const checkUntilHasValue = (
  variable: string[],
  tries: number,
  callback: Function,
) => {
  let tempTries = tries;
  const checkVariable = setInterval(() => {
    tempTries--;
    let myVariable = window;
    variable.map((el) => {
      // @ts-ignore
      myVariable = myVariable[el];
    });
    if (myVariable || tempTries < 0) {
      callback(myVariable);
      clearInterval(checkVariable);
    }
  }, 400);
};
