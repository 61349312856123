import BaseService from "./base-service";
import Stripe from "stripe";
import { User } from "@/types/user";

class StripeService extends BaseService {
  constructor(_rootEndpoint: string) {
    super(_rootEndpoint);
  }

  public getAccToken = async (code: string) => {
    const result: any = await this.get(
      `${this.getServiceContext}/stripe-id/${code}`,
    );
    return result.id;
  };

  public connectAccount = async (query: {
    settings: boolean;
  }): Promise<any> => {
    try {
      const { url } = await this.get(
        `${
          this.getServiceContext
        }/authentication-url?settings=${query.settings.toString()}`,
      );

      if (!process.server) window.location.href = url;
    } catch (error) {
      console.error(error);
    }
  };

  public addCard = async (
    holderName: string,
    cardToken: string,
  ): Promise<Stripe.PaymentMethod> => {
    return this.post(`${this.getServiceContext}/card`, {
      holderName,
      cardToken,
    });
  };

  public getSavedCards = async (): Promise<Stripe.PaymentMethod[]> => {
    return this.get(`${this.getServiceContext}/saved-cards`);
  };

  public createStripeCustomer = async (): Promise<User> => {
    return this.update(`${this.getServiceContext}/customer`, {});
  };
}

export default StripeService;
