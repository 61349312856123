import { useAuthenticationStore } from "@/store/authentication";
import {
  FileData,
  RequestUploadLinkData,
  UploadData,
  UploadResponse,
  UploadScope,
} from "@/types/upload";
import { Exception } from "@/utils/error";
import BaseService from "./base-service";
import axios, { CancelToken } from "axios";

export default class UploadService extends BaseService {
  constructor(_rootEndpoint: string) {
    super(_rootEndpoint);
  }

  public async uploadFileWithAxios(
    data: FileData,
    progress: (progressEvent: any) => void,
    cancelToken: CancelToken,
  ): Promise<UploadResponse> {
    let mimetype = data.file?.type;
    if (mimetype.includes(";")) {
      mimetype = mimetype.split(";")[0];
    }
    const requestObj: RequestUploadLinkData = { ...data, mimetype };
    delete requestObj.file;
    let headers = this.getHeaders;
    const authStore = useAuthenticationStore();
    if (authStore.authUser.authToken) {
      headers.set("Authorization", `Bearer ${authStore.authUser.authToken}`);
    }
    const uploadDataResponse = await fetch(`${this.getServiceContext}`, {
      method: "POST",
      body: JSON.stringify(requestObj),
      headers: this.getHeaders,
    });
    const uploadData = await this.checkResponse<UploadData>(uploadDataResponse);
    if (!uploadData) return null as any;

    const formData = new FormData();

    Object.entries(uploadData.upload.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("file", data.file, data.file.name);
    headers = new Headers();

    const uploadedResponse = await axios.post(uploadData.upload.url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      cancelToken,
      onUploadProgress: progress,
    });

    if (uploadedResponse.status != 200) {
      throw new Exception(
        "BadRequest",
        "Upload failed, please try again later",
      );
    }
    return uploadedResponse.data;
  }

  public async getValidFileURL(fileKey: string): Promise<string> {
    const authStore = useAuthenticationStore();
    if (!authStore.authUser.authToken) {
      throw new Exception(
        "Unauthorized",
        "You are not authorized to access this resource.",
      );
    }
    const fetchResponse = await fetch(
      this.buildRequest(
        `${this.getServiceContext}/get-valid-file?fileKey=${escape(fileKey)}`,
        "GET",
      ),
    );
    const json = await fetchResponse.json();
    return json.fileURL || "";
  }

  public async uploadFile(data: FileData): Promise<UploadResponse> {
    if (data.scope === UploadScope.userProfile) {
      if (data.file.size >= 2000000)
        throw new Exception(
          "BadRequest",
          "Image size exceeds maximum allowable size 2Mb",
        );
    } else {
      if (data.file.size >= 100000000)
        throw new Exception(
          "BadRequest",
          "File size exceeds maximum allowable size 100Mb",
        );
    }

    let mimetype = data.file?.type;
    if (mimetype.includes(";")) {
      mimetype = mimetype.split(";")[0];
    }
    const requestObj: RequestUploadLinkData = { ...data, mimetype };
    delete requestObj.file;
    let headers = this.getHeaders;
    const authStore = useAuthenticationStore();
    if (authStore.authUser.authToken) {
      headers.set("Authorization", `Bearer ${authStore.authUser.authToken}`);
    }
    const uploadDataResponse = await fetch(`${this.getServiceContext}`, {
      method: "POST",
      body: JSON.stringify(requestObj),
      headers: this.getHeaders,
    });
    const uploadData = await this.checkResponse<UploadData>(uploadDataResponse);
    if (!uploadData) return null as any;

    const formData = new FormData();

    Object.entries(uploadData.upload.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("file", data.file, data.file.name);
    headers = new Headers();

    const uploadedResponse = await fetch(uploadData.upload.url, {
      method: "POST",
      body: formData,
      headers: headers,
    });
    if (!uploadedResponse.ok) {
      throw new Exception(
        "BadRequest",
        "Image size exceeds maximum allowable size 10Mb",
      );
    }
    const resp = await this.checkResponse<UploadResponse>(uploadedResponse);

    return resp;
  }
}
