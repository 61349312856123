import { defineStore } from "pinia";
import SessionDataService from "@/services/session-data-service";
import { Faq } from "@/types/session-data";

const sessionDataService = new SessionDataService("session-data");

export const useSessionDataStore = defineStore("session-data", {
  state: () => ({
    faqs: [] as Faq[],
    signInSpotifyShown: false,
  }),
  actions: {
    async fetchData(): Promise<void> {
      try {
        const sessionData = await sessionDataService.fetchSessionData();
        this.faqs = sessionData.faqs;
      } catch (e) {
        console.error("Error fetching data:", e);
      }
    },
  },
});
