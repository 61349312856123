import AuthenticationService from "@/services/authentication-service";
import {
  Authentication,
  ModalState,
  ProCandidate,
  ProCandidateRegister,
} from "@/types/authentication";
import { handleError } from "@/utils/error";
import { defineStore } from "pinia";
import { useUserStore } from "./user";
import { useGtm } from "@gtm-support/vue-gtm";
import dayjs from "dayjs";
import AnalyticsService from "@/services/analytics-service";
import { User } from "@/types/user";

const authService = new AuthenticationService("auth");
const profileUser = () => useUserStore();
const analyticsService = new AnalyticsService("analytics");

export const useAuthenticationStore = defineStore("authentication", {
  state: () => ({
    modalState: { isModalVisible: false } as ModalState,
    authUser: { authToken: "" },
    isImpersonating: false,
  }),
  actions: {
    setModalState(newState: ModalState) {
      if (newState.redirectUrl) {
        localStorage.setItem("redirectUrl", newState.redirectUrl);
      }
      if (newState.isModalVisible && this.authUser.authToken) {
        useRouter().push("/dashboard");
      } else {
        this.modalState = newState;
      }
    },

    setToken(authToken: string) {
      this.authUser = { authToken };
      localStorage.setItem("authToken", authToken);
    },

    getAuthService() {
      return authService;
    },

    async login(
      email: string,
      password: string,
    ): Promise<Authentication | void> {
      try {
        const res = await authService.login(email, password);
        if (res && res?.authToken && res?.user) {
          analyticsService.identify(res.user);
          this.setData(res);

          const chatStore = (await import("@/store/chat")).useChatStore();
          if (!process.server) chatStore.initChatService();
        }
        return res;
      } catch (error) {
        throw handleError(error);
      }
    },

    async googleLogin(
      credentials: {
        id_token: string;
        access_token: string;
        refresh_token?: string;
      },
      code?: string,
    ): Promise<Authentication | void> {
      try {
        const googleDto = {
          code,
          ...credentials,
        };
        const res = await authService.googleLogin(googleDto);
        if (res && res?.authToken) {
          analyticsService.identify(res.user);
          this.setData(res);
        }
        return res;
      } catch (error) {
        throw handleError(error);
      }
    },

    async twitterCallback(
      state: string,
      code: string,
      code_verifier: string,
    ): Promise<any | void> {
      try {
        return await authService.twitterCallback(state, code, code_verifier);
      } catch (error) {
        throw handleError(error);
      }
    },

    async googleCallback(
      code: string,
      candidateCode: string,
    ): Promise<{ auth_token: string }> {
      try {
        return await authService.googleCallback(code, candidateCode);
      } catch (error) {
        throw handleError(error);
      }
    },

    async googleLinkCallback(code: string, state: string): Promise<User> {
      try {
        return await authService.googleLinkCallback(code, state);
      } catch (error) {
        throw handleError(error);
      }
    },

    async getTwitterLoginUrl(): Promise<{
      url: string;
      verifier: string;
    }> {
      try {
        return await authService.getTwitterLoginUrl();
      } catch (error) {
        throw handleError(error);
      }
    },
    async twitterLogin(
      twitterAccessToken: string,
      code?: string,
    ): Promise<Authentication | void> {
      try {
        const res = await authService.twitterLogin(twitterAccessToken, code);
        if (res && res?.authToken) {
          analyticsService.identify(res.user);
          this.setData(res);
        }
        return res;
      } catch (error) {
        throw handleError(error);
      }
    },

    async register(
      fullName: string,
      email: string,
      password: string,
      code?: string,
    ): Promise<Authentication | void> {
      try {
        const res = await authService.register(
          fullName,
          email,
          password,
          dayjs.tz.guess(),
          code,
        );
        if (res && res?.authToken) {
          const gtm = useGtm();
          gtm?.trackEvent({
            event: "signup",
            authenticationMethod: "email",
            userId: res.user._id,
          });
          analyticsService.identify(res.user);
          this.setData(res);
        }
      } catch (error) {
        throw handleError(error);
      }
    },

    async applyPro(props: ProCandidateRegister): Promise<ProCandidate | void> {
      try {
        await authService.applyPro(props);
      } catch (error) {
        throw handleError(error);
      }
    },

    async forgotPassword(email: string): Promise<void> {
      try {
        await authService.forgotPassword(email);
      } catch (error) {
        throw handleError(error);
      }
    },

    async resetPassword(newPassword: string, token: string): Promise<void> {
      try {
        await authService.resetPassword(newPassword, token);
      } catch (error) {
        throw handleError(error);
      }
    },

    async logout() {
      try {
        await authService.logout();
      } catch (e) {
        console.error(e);
      }
      this.authUser = { authToken: "" };
      profileUser().logout();
      analyticsService.reset();

      localStorage.removeItem("authToken");
      localStorage.removeItem("user");
      localStorage.removeItem("isImpersonating");
      sessionStorage.removeItem("showSidebar");
    },

    async impersonate(authToken: string): Promise<Authentication | void> {
      try {
        localStorage.authToken = authToken;
        this.authUser.authToken = authToken;

        const user = profileUser().userData;
        if (user) {
          this.setData({ authToken, user });
        }
      } catch (error) {
        throw handleError(error);
      }
    },

    setData(data: Authentication): void {
      localStorage.authToken = data.authToken;
      localStorage.setItem("user", JSON.stringify(data.user));
      this.authUser.authToken = data.authToken;
      profileUser().userData = data.user;
    },

    setAuthentication(): void {
      const accessToken = localStorage.getItem("authToken");
      if (accessToken) {
        this.authUser.authToken = accessToken;
      }

      const userProfile = localStorage.getItem("user");

      if (userProfile && userProfile !== "undefined") {
        profileUser().userData = JSON.parse(userProfile);
      } else {
        localStorage.removeItem("user");
      }
    },
  },
});
