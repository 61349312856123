export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"description","content":"Get Funding Without Selling Your Rights. Access Our Exclusive Network of 100+ Industry Pros Ready to Mentor and Review Your Music. Sell Your Music Catalog. Xposure Music is Where Artists Level Up."},{"name":"og:description","content":"Get Funding Without Selling Your Rights. Access Our Exclusive Network of 100+ Industry Pros Ready to Mentor and Review Your Music. Sell Your Music Catalog. Xposure Music is Where Artists Level Up."},{"name":"twitter:description","content":"Get Funding Without Selling Your Rights. Access Our Exclusive Network of 100+ Industry Pros Ready to Mentor and Review Your Music. Sell Your Music Catalog. Xposure Music is Where Artists Level Up."},{"property":"og:image","content":"https://ik.imagekit.io/xposuremusic/xposuremusic.png"},{"property":"twitter:image","content":"https://ik.imagekit.io/xposuremusic/xposuremusic.png"},{"property":"og:image:secure_url","content":"https://ik.imagekit.io/xposuremusic/xposuremusic.png"},{"property":"og:image:alt","content":"Xposure Music"},{"property":"og:image:type","content":"image/svg"},{"property":"og:image:width","content":"512"},{"property":"og:image:height","content":"512"},{"property":"og:title","content":"Xposure Music - Get Funding and Grow Your Network"},{"property":"twitter:title","content":"Xposure Music - Get Funding and Grow Your Network"},{"property":"robots","content":"index, follow"}],"link":[{"rel":"icon","type":"image/png","href":"/icon.png"},{"rel":"apple-touch-icon","type":"image/png","href":"/icon.png"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"en"},"title":"Xposure Music - Get Funding and Grow Your Network"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"