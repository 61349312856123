import { useAuthenticationStore } from "@/store/authentication";
import { useUserStore } from "@/store/user";
import { User, UserType } from "@/types/user";
import AnalyticsService from "~/services/analytics-service";
import config from "@/config";
import { setCookie } from "@/utils/helpers";

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.server) {
    return;
  }

  const showHeader = useSessionStorage("showHeader", true);
  showHeader.value = true;

  const analyticsService = new AnalyticsService("analytics");
  analyticsService.page(to.fullPath);
  const authStore = useAuthenticationStore();
  authStore.isImpersonating =
    localStorage.getItem("isImpersonating") === "true";
  if (to.query?.auth_token) {
    const { auth_token, ...newQuery } = to.query;
    localStorage.setItem("authToken", to.query.auth_token as string);
    authStore.setToken(to.query.auth_token as string);
    const userStore = useUserStore();
    await userStore.getMe();
    return navigateTo({ ...to, query: newQuery });
  }

  if (
    localStorage.getItem("redirectAfterSignIn") &&
    localStorage.getItem("authToken")
  ) {
    const redirectAfterSignIn = localStorage.getItem("redirectAfterSignIn");
    localStorage.removeItem("redirectAfterSignIn");
    return navigateTo(redirectAfterSignIn);
  }

  if (to.meta.requiresAuth && !localStorage.getItem("authToken")) {
    localStorage.setItem("redirectAfterSignIn", to.path);
    setCookie("signup_page_source", to.path, 30);
    return navigateTo("/signup");
  }
  const jsonUser = localStorage.getItem("user");
  const user: User = JSON.parse(jsonUser || "{}");
  if (
    to.meta.requiresAuth &&
    user.type === UserType.pro &&
    !user.isEnabled &&
    to.path !== "/post-onboarding"
  ) {
    return navigateTo("/post-onboarding");
  }
  if (to.meta.requiresPro && user.type !== UserType.pro) {
    const redirectUrl = localStorage.getItem("redirectUrl");
    localStorage.removeItem("redirectUrl");
    return navigateTo(redirectUrl || "/dashboard");
  }
  useHead({
    link: [
      {
        rel: "canonical",
        href: `${config("baseUrl")}${to.path}`,
      },
    ],
  });
});
