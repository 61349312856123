import { Product } from "./product";
import { Track } from "./track";
import { OrderRating } from "@/types/order";

export enum UserType {
  customer = "customer",
  pro = "pro",
}

export enum UserStatus {
  active = "active",
  suspended = "suspended",
  pending = "pending",
}

export enum Gender {
  female = "female",
  male = "male",
  other = "other",
  preferNotToSay = "preferNotToSay",
}

export const GenderLabels = {
  [Gender.female]: "Female",
  [Gender.male]: "Male",
  [Gender.other]: "Other",
  [Gender.preferNotToSay]: "Prefer not to say",
};

export interface UserPortfolio {
  musicVideo?: string;
  bestTrackId?: string;
  bestTrack?: Track;
  recentTrackId?: string;
  recentTrack?: Track;
  upcomingTrackId?: string;
  upcomingTrack?: Track;
}

export interface ProfileCompleteness {
  complete: boolean;
  basicInformation: boolean;
  addTracks: boolean;
  requestPaidProduct: boolean;
}

export interface RelatedPros {
  job: string;
  related: User[];
}

export interface ArtistStatistics {
  spotifyFollowers?: number;
  spotifyMonthlyListeners?: number;

  youtubeMonthlyListeners?: number;

  SoundCloudFollowersCount?: number;
  SoundCloudLikesCount?: number;

  tiktokFollowers?: number;
  tiktokLikes?: number;
}

export const artistStatisticsKeys = [
  {
    key: "spotifyFollowers",
    label: "Spotify Followers",
    icon: "icon-spotify",
  },
  {
    key: "spotifyMonthlyListeners",
    label: "Spotify Monthly Listeners",
    icon: "icon-spotify",
  },
  {
    key: "youtubeMonthlyListeners",
    label: "Youtube Monthly Listeners",
    icon: "icon-youtube",
  },
  {
    key: "SoundCloudFollowersCount",
    label: "SoundCloud Followers",
    icon: "icon-soundcloud",
  },
  {
    key: "SoundCloudLikesCount",
    label: "SoundCloud Likes",
    icon: "icon-soundcloud",
  },
  {
    key: "tiktokFollowers",
    label: "Tiktok Followers",
    icon: "icon-tiktok",
  },
  {
    key: "tiktokLikes",
    label: "Tiktok Likes",
    icon: "icon-tiktok",
  },
];

export interface User {
  _id: string;
  email: string;
  phone?: string;

  firstName: string;
  lastName: string;
  uniqueName: string;
  redirectsFrom?: string;
  stageName?: string;
  title?: string;
  status: UserStatus;
  type: UserType;
  profilePicture?: string;
  profileVideo?: string;
  profilePictureUrl?: string;
  profileCompleteness: ProfileCompleteness;
  country?: string;
  zipCode?: string;
  gender?: Gender;
  birthDate?: Date;
  city?: string;
  bio?: string;
  canHelpWith?: string;
  socialLinks?: {
    [key in SocialLinkProvider | ProSocialLinkProvider]?: string;
  };
  portfolio?: UserPortfolio;
  genres?: string[];
  stripeCustomerId?: string;

  // pro only
  job?: ProJobTitle[];
  pricing?: ProPriceCategory;
  priceStartingFrom: number;
  worksAt?: string;
  isWishlisted?: boolean;
  stripeConnectId?: string;
  experiences?: WorkExperience[];
  connections?: string[];
  products?: Product[];
  isEnabled?: boolean;
  social?: {
    google?: { id: string };
    twitter?: { id: string };
  };
  calendarAccess?: boolean;
  timezone: string;
  testAccount?: boolean;

  feedBacksPerWeek?: number;
  consultationCallsPerWeek?: number;

  consultationCallCount?: number;
  feedBackCount?: number;

  mostActiveSocialLinkURL?: string;
  whatDescribeYouBest?: string;

  earnedTotal?: number;

  spotifyMonthlyListeners?: number;
  ratings: OrderRating[];
}

export enum Genre {
  "Hip-hop/Rap" = "Hip-hop/Rap",
  "R&B/Soul/Funk" = "R&B/Soul/Funk",
  "Country/Blues/Folk" = "Country/Blues/Folk",
  "Dance/Electronic" = "Dance/Electronic",
  "Pop" = "Pop",
  "Latin" = "Latin",
  "Rock" = "Rock",
  "Jazz" = "Jazz",
}

export enum ProJobTitle {
  "A&R" = "A&R",
  "Marketer" = "Marketer",
  "Sound Engineer" = "Sound Engineer",
  "Songwriter" = "Songwriter",
  "Producer" = "Producer",
  "Manager" = "Manager",
}

export enum XposureServices {
  "Music Funding" = "Music Funding",
  "Connect with Music Pros" = "Connect with Music Pros",
  "Discover Opportunities" = "Discover Opportunities",
}
export enum City {
  "New York" = "New York",
  Atlanta = "Atlanta",
  "Los Angeles" = "Los Angeles",
  Nashville = "Nashville",
  Montreal = "Montreal",
  Miami = "Miami",
}

export enum ProPriceCategory {
  "$0-$50" = "$0-$50",
  "$50-$100" = "$50-$100",
  "$100-$150" = "$100-$150",
  "$150-$200" = "$150-$200",
  "$200-$250" = "$200-$250",
  "$250+" = "$250+",
  "free" = "free",
}

export enum SocialLinkProvider {
  spotify = "spotify",
  soundcloud = "soundcloud",
  instagram = "instagram",
  twitter = "twitter",
  tiktok = "tiktok",
  twitch = "twitch",
  personalSite = "personalSite",
}

export enum ProSocialLinkProvider {
  instagram = "instagram",
  twitter = "twitter",
  linkedin = "linkedin",
  youtube = "youtube",
  personalSite = "personalSite",
  tiktok = "tiktok",
  spotify = "spotify",
  soundcloud = "soundcloud",
}

export type SocialLink =
  | keyof typeof ProSocialLinkProvider
  | keyof typeof SocialLinkProvider;
export interface linkProvider {
  icon: string;
  label: string;
  escapedLabel: string;
  value: SocialLink;
}

export const SocialLinkProviderInfo = [
  {
    icon: "instagram",
    label: "instagram.com/",
    escapedLabel: "instagram.com/",
    value: SocialLinkProvider.instagram,
  },
  {
    icon: "x",
    label: "x.com/",
    escapedLabel: "twitter.com/",
    value: SocialLinkProvider.twitter,
  },
  {
    icon: "tiktok",
    label: "tiktok.com/@",
    escapedLabel: "tiktok.com/@",
    value: SocialLinkProvider.tiktok,
  },
  {
    icon: "twitch",
    label: "twitch.tv/",
    escapedLabel: "twitch.tv/",
    value: SocialLinkProvider.twitch,
  },
];

export const ProSocialLinkProviderInfo = [
  {
    icon: "instagram",
    label: "instagram.com/",
    escapedLabel: "instagram.com/",
    value: ProSocialLinkProvider.instagram,
  },
  {
    icon: "x",
    label: "x.com/",
    escapedLabel: "twitter.com/",
    value: ProSocialLinkProvider.twitter,
  },
  {
    icon: "linkedin",
    label: "linkedin.com/in/",
    escapedLabel: "linkedin.com/in/",
    value: ProSocialLinkProvider.linkedin,
  },
  {
    icon: "youtube",
    label: "youtube.com/@",
    escapedLabel: "youtube.com/@",
    value: ProSocialLinkProvider.youtube,
  },
];

export interface WorkExperience {
  _id?: string;
  title: string;
  company: string;
  currentlyWorking: boolean;
  startYear: number;
  startMonth: string;
  endYear?: number;
  endMonth?: string;
}

export interface UpdateProError {
  firstNameError: string;
  lastNameError: string;
  emailError: string;
  countryError: string;
  locationError: string;
  instagramError: string;
  twitterError: string;
  linkedinError: string;
  youtubeError: string;
  personalSiteError: string;
  jobError: string;
  bioError: string;
  canHelpWithError: string;
  worksAtError: string;
}
export interface UpdateUserError {
  stageNameError: string;
  firstNameError: string;
  lastNameError: string;
  countryError: string;
  locationError: string;
  bioError: string;
  instagramError: string;
  twitterError: string;
  spotifyError: string;
  soundcloudError: string;
  musicVideoError: string;
  bestTrackError: string;
}
