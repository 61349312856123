import { Product } from "@/types/product";
import { useGtm } from "@gtm-support/vue-gtm";
import config from "@/config";
import { Coupon } from "@/types/coupon";
import BaseService from "@/services/base-service";
import { getGAVariables } from "@/utils/helpers";
import { FeedbackDetails } from "@/store/products";
import { User } from "@/types/user";

export interface intervalEvent {
  type: string;
  args: any[];
}

export class AnalyticsService extends BaseService {
  constructor(_rootEndpoint: string) {
    super(_rootEndpoint);
  }

  private static pendingInterval: NodeJS.Timer | undefined;
  private static pendingEvents: intervalEvent[] = [];

  private checkPending = () => {
    if (window && window.analytics) {
      clearInterval(AnalyticsService.pendingInterval);
      while (AnalyticsService.pendingEvents.length) {
        const event = AnalyticsService.pendingEvents.shift();
        if (event) {
          switch (event.type) {
            case "page":
              this.page(event.args[0]);
              break;
            case "event":
              this.trackEvent(event.args[0], event.args[1]);
          }
        }
      }
    }
  };

  public page(url: string) {
    try {
      if (window && window.analytics) {
        window.analytics.page(url);
      } else {
        if (!process.server) {
          AnalyticsService.pendingEvents.push({ type: "page", args: [url] });
          if (!AnalyticsService.pendingInterval) {
            AnalyticsService.pendingInterval = setInterval(
              this.checkPending,
              100,
            );
          }
        }
      }
    } catch (e) {
      console.error("Analytics error: " + e);
    }
  }

  public trackEvent(eventName: string, eventData: any) {
    try {
      if (!process.server && window && window.analytics) {
        window.analytics.track(eventName, { ...eventData });
      } else {
        if (!process.server) {
          AnalyticsService.pendingEvents.push({
            type: "event",
            args: [eventName, eventData],
          });
          if (!AnalyticsService.pendingInterval) {
            AnalyticsService.pendingInterval = setInterval(
              this.checkPending,
              100,
            );
          }
        }
      }
    } catch (e) {
      console.error("Analytics error: " + e);
    }
  }

  public identify(user: User) {
    try {
      window.analytics && window.analytics.identify(user._id.toString());
    } catch (e) {
      console.error("Analytics error: " + e);
    }
  }

  public reset() {
    try {
      window.analytics && window.analytics.reset();
    } catch (e) {
      console.error("Analytics error: " + e);
    }
  }

  public trackCheckoutInitiated = async (productId: string): Promise<void> => {
    const analytics = await getGAVariables();
    await this.post(`${this.getServiceContext}/checkout-initiated`, {
      productId,
      analytics,
    });

    setTimeout(() => {
      this.trackCheckoutStepView(productId, 1);
    }, 200);
  };

  public trackCheckoutStepView = async (
    productId: string,
    step: number,
  ): Promise<void> => {
    return this.post(`${this.getServiceContext}/checkout-step-view`, {
      productId,
      step,
    });
  };
  public trackCheckoutStepDone = async (
    productId: string,
    step: number,
  ): Promise<void> => {
    return this.post(`${this.getServiceContext}/checkout-step-done`, {
      productId,
      step,
    });
  };
  public trackCheckoutSurveyCompleted = async (
    productId: string,
    feedbackDetails: Partial<FeedbackDetails>,
  ): Promise<void> => {
    return this.post(`${this.getServiceContext}/checkout-survey-completed`, {
      productId,
      ...feedbackDetails,
    });
  };
  public trackFeedbackRequestView = async (orderId: string): Promise<void> => {
    return this.post(`${this.getServiceContext}/feedback-request-view`, {
      orderId,
    });
  };

  public trackPurchaseCompleted(
    currentProduct: Partial<Product>,
    coupon: Coupon,
    payment: any,
    totalPaid: number,
  ) {
    const gtm = useGtm();
    gtm?.trackEvent({
      event: "purchase",
      transaction_id: payment.id,
      value: totalPaid,
      tax: 0,
      shipping: 0,
      currency: "USD",
      coupon: payment.metadata?.couponCode ?? "",
      items: [
        {
          item_id: currentProduct._id,
          item_name: currentProduct.proName,
          item_category: currentProduct.type,
          currency: "USD",
          price: currentProduct.price,
          quantity: 1,
        },
      ],
    });

    window &&
      window.analytics &&
      window.analytics.track("Order Completed", {
        checkout_id: currentProduct.checkoutId,
        total: currentProduct?.price,
        subtotal:
          (currentProduct?.price || 0) -
          (coupon.percentOff * (currentProduct?.price || 0) || 0),
        revenue:
          (currentProduct?.price || 0) *
            parseInt(config("applicationFeePercentage")) -
          (coupon.percentOff * (currentProduct?.price || 0) || 0),
        tax:
          (currentProduct?.price || 0) *
          parseInt(config("stripeFeePercentage")),
        discount: coupon.percentOff * (currentProduct?.price || 0),
        coupon: coupon.coupon,
        currency: "USD",
        quantity: 1,
        url: `${config("baseUrl")}/${currentProduct.pro?.uniqueName || ""}`,
      });
  }

  public trackButtonClicked(
    name: string,
    location: string,
    type: string,
    signed_up: boolean,
  ) {
    this.trackEvent("Button Clicked", {
      button_name: name,
      button_location: location,
      button_type: type,
      signed_up,
    });
  }

  public trackArtistPageViewed(
    artistId: string,
    source: "Music Library" | "Opportunity" | "",
  ) {
    this.trackEvent("Viewed Artist Profile", {
      customer_id: artistId,
      source,
    });
  }

  public trackProViewedSideBar(
    artistId: string,
    source: "Music Library" | "Opportunity" | "",
  ) {
    this.trackEvent("Viewed Side Bar", {
      customer_id: artistId,
      source,
    });
  }

  public trackProAppliedFilter(
    filter: any,
    results: number,
    source: "Music Library" | "Opportunity",
  ) {
    this.trackEvent("Pro Applied Filter", {
      filter,
      results,
      source,
    });
  }
  public trackProResetsFilters(source: "Music Library" | "Opportunity") {
    this.trackEvent("Pro Removed Filter", { source });
  }
}

export default AnalyticsService;
