import BaseService from "@/services/base-service";
import { SessionData } from "@/types/session-data";

class SessionDataService extends BaseService {
  constructor(_rootEndpoint: string) {
    super(_rootEndpoint);
  }

  public async fetchSessionData(): Promise<SessionData> {
    try {
      return await this.get(`${this.getServiceContext}/`);
    } catch (e) {
      throw handleError(e);
    }
  }
}

export default SessionDataService;
