// plugins/pinia-plugin-persist.client.ts
import { Context } from "@nuxt/types";
// import piniaPersist from "pinia-plugin-persist";
import "@/styles/app.scss";
import config from "../config";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();

  if (config("sentryURL")) {
    try {
      Sentry.init({
        app: nuxtApp.vueApp,
        dsn: config("sentryURL"),
        integrations: [
          new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(
              router as any,
            ),
            tracingOrigins: ["localhost", "my-site-url.com", /^\//],
          }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
    } catch (error) {
      console.log("Sentry init error", error);
    }
  } else {
    console.log("Sentry is not configured");
  }
});
