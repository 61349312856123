import { handleError } from "@/utils/error";
import { ArtistStatistics, User } from "@/types/user";
import BaseService from "./base-service";
import { ProBusyTime } from "@/types/pro-availability";

class UserService extends BaseService {
  constructor(_rootEndpoint: string) {
    super(_rootEndpoint);
  }

  public async getByUniqueName(uniqueName: string): Promise<User> {
    return await this.get(`${this.getServiceContext}/unique/${uniqueName}`);
  }

  public async getArtistStatistics(userId: string): Promise<ArtistStatistics> {
    return await this.get(`${this.getServiceContext}/statistics/${userId}`);
  }

  public async sendMessage(userId: string, message: string) {
    return await this.post(`${this.getServiceContext}/send-message`, {
      message,
      userId,
    });
  }

  public async getMe(): Promise<User> {
    return await this.get(`${this.getServiceContext}/me`);
  }

  public async canChat(proId: string): Promise<{ canChat: boolean }> {
    return await this.get(`${this.getServiceContext}/me/can-chat/${proId}`);
  }

  public async updateMyPrices(): Promise<{ user: User }> {
    return await this.update(`${this.getServiceContext}/prices`, {});
  }

  public async updatePurchase(
    instagram: string,
    whatDescribeYouBest: string,
  ): Promise<User> {
    return await this.update(`${this.getServiceContext}/me/purchase`, {
      instagram,
      whatDescribeYouBest,
    });
  }

  public async updateMe(data: Partial<User>): Promise<User> {
    return await this.update(`${this.getServiceContext}/me`, data);
  }

  public async goLive(): Promise<User> {
    return await this.post(`${this.getServiceContext}/go-live`, {});
  }

  public async updateTimezone(timezone: string): Promise<User> {
    return await this.update(`${this.getServiceContext}/timezone`, {
      timezone,
    });
  }

  public async removeProfilePicture(): Promise<User> {
    return await this.delete(`${this.getServiceContext}/me/profilePicture`, {});
  }

  public async getById(id: string): Promise<User | void> {
    try {
      return await this.get(`${this.getServiceContext}/${id}`);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async getCurrentProBusyTime(
    startDate: Date,
    endDate: Date,
  ): Promise<ProBusyTime> {
    return await this.get(
      `${
        this.getServiceContext
      }/me/busy-time?startDate=${startDate.getTime()}&endDate=${endDate.getTime()}`,
    );
  }
}

export default UserService;
