export interface Upload {
  _id: string;
  key: string;
  url: string;
  mimetype: string;
  scope: string;
}
export interface FileData {
  name: string;
  scope: UploadScope;
  owner: string;
  file: File;
}

export interface RequestUploadLinkData {
  mimetype: string;
  name: string;
  scope: UploadScope;
  owner: string;
  file?: File;
}

export interface UploadResponse {
  url: string;
  key: string;
  upload: Upload;
  [key: string]: any;
}

export interface UploadData {
  upload: {
    url: string;
    fields: {
      "Content-Type": string;
      key: string;
      success_action_redirect: string;
      bucket: string;
      "X-Amz-Algorithm": string;
      "X-Amz-Credential": string;
      "X-Amz-Date": string;
      Policy: string;
      "X-Amz-Signature": string;
    };
  };
}

export enum UploadScope {
  userProfile = "userProfilePicture",
  userProfileVideo = "userProfileVideo",
  proFeedback = "userFeedbackFile",
  userTrack = "userTrack",
}

export interface UploadItem {
  name: string;
  scope: UploadScope;
  owner: string;
  extension?: string;
  file: File;
}
