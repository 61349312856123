<template>
  <div>
    <ClientOnly>
      <div
        v-if="useAuthenticationStore().isImpersonating"
        class="absolute top-0 left-0 w-full bg-red z-50 text-center py-1"
      >
        IMPERSONATE MODE
      </div>
    </ClientOnly>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { useAuthenticationStore } from "./store/authentication";
import { useUserStore } from "./store/user";
import { useSessionDataStore } from "./store/session-data";

if (!process.server) {
  const userStore = useUserStore();
  const sessionDataStore = useSessionDataStore();
  const authenticationStore = useAuthenticationStore();
  authenticationStore.setAuthentication();

  onMounted(async () => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      await userStore.getMe();
    }
    sessionDataStore.fetchData().then((r) => {
      console.log("Session data fetched successfully");
    });
  });
}

useSessionStorage("showHeader", true).value = true;
</script>

<style scoped></style>
